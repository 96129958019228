<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        :submit="saveData"
        :initialValues="initialValues"
        class="w-full form-col-2 pr-2"
        ref="form"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >remote/card/code</label
            >
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >remote control/card/code for gate access</label
          >
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="remoteControlForGate"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title">remote control for gate access</div>
          </div>
          <div class="form-row ml-8">
            <div class="text-title">fee for additional remote controls</div>
            <TextInputSmall type="number" name="remoteControlForGateFee" />
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="cardsForGateAccess"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title">cards for gate access</div>
          </div>
          <div class="form-row ml-8">
            <div class="text-title">fee for additional cards</div>
            <TextInputSmall type="number" name="cardsForGateAccessFee" />
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="codeForGateAccess"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title">code for gate access</div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >damaged, lost or unreturned remote controls, cards, or code
            changes</label
          >
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="lostRemoteControl"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">lost remote control</div>
          </div>
          <div class="form-row ml-8">
            <div class="text-title">replacement fee</div>
            <TextInputSmall type="number" name="remoteControlReplacementFee" />
          </div>
          <div class="form-row ml-8">
            <div class="text-title">security deposit will be reduced by</div>
            <TextInputSmall type="number" name="remoteControlSecurityDepoit" />
            <div class="text-title">if not returned</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="lostCards"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">lost cards</div>
          </div>
          <div class="form-row ml-8">
            <div class="text-title">replacement fee</div>
            <TextInputSmall type="number" name="cardsReplacementFee" />
          </div>
          <div class="form-row ml-8">
            <div class="text-title">security deposit will be reduced by</div>
            <TextInputSmall type="number" name="lostCardSecurityDepoit" />
            <div class="text-title">if not returned</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="codeChange"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">code change</div>
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import TextInputSmall from "@/components/form/TextInputSmall";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import { FinalForm } from "vue-final-form";

export default {
  name: "RemoteCardCode",
  components: {
    Loader,
    ModalFooter,
    FinalForm,
    TextInputSmall,
    CheckboxInputSmall,
  },
  computed: {
    initialValues() {
      return {
        remoteControlForGate: "",
        remoteControlForGateFee: "",
        cardsForGateAccess: "",
        cardsForGateAccessFee: "",
        codeForGateAccess: "",
        lostRemoteControl: "",
        remoteControlReplacementFee: "",
        remoteControlSecurityDepoit: "",
        lostCards: "",
        cardsReplacementFee: "",
        lostCardSecurityDepoit: "",
        codeChange: "",
      };
    },
  },
  mixins: [ModalNavigation, InitializeFormMixin],
  data() {
    return {
      loading: false,
      footer: {
        primaryButton: "save",
      },
      remoteControlForGate: "",
      remoteControlForGateFee: "",
      cardsForGateAccess: "",
      cardsForGateAccessFee: "",
      codeForGateAccess: "",
      lostRemoteControl: "",
      remoteControlReplacementFee: "",
      remoteControlSecurityDepoit: "",
      lostCards: "",
      cardsReplacementFee: "",
      lostCardSecurityDepoit: "",
      codeChange: "",
    };
  },
  methods: {
    saveData() {},
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>